import React, { Component } from 'react'
import './style.css'

class ArtExhibits extends Component {
  render () {
    return (
      <div className=''>
        <h1 className=''>ArtExhibits COMPONTENT</h1>
      </div>
    )
  }
}

export default ArtExhibits
