import React, { Component } from 'react'
import './style.css'

class HomePage extends Component {
  render () {
    return (
      <div className='main-page-container'>
        <img className='homepage-img' src='https://i.imgur.com/3k4W8cv.jpg' alt='' />
      </div>
    )
  }
}

export default HomePage
